import { Breadcrumbs, MenuItem, Menu, Grid, Tab, Typography, Modal, Button, Box, Stack } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled, alpha } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import ConvenienceFeeMgt from "../pages/ConvenienceFeeMgt";
import PocketMoneyFeeConfig from "./PocketMoneyFee/pocketMoneyconfig";
import WarningImg from "../../../images/warning.png"
import EditPocketMoneyConfig from "./PocketMoneyFee/EditPocketMoneyFee";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import ConfigurationFunctions from "../../configuration/configEndpoints";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import CustomDataGrid from "../../products/stockComponents/customDataGrid";
import AnnualMaintenanceFee from "../pages/AnnualMaintenanceFee";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import Breadcrumb from "./Breadcrumbs/Breadcrumbs";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ZpmMasterAccountSetUp from "./zpm/zpmMasterAccountSet";
import { DoMaskValue } from "../../../utils/dateFormatter";
import ZPMEditCardAccount from "./zpm/zpmeditCardAccount";
import EditZpmConfigs from "./editConfigs";

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };



// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: 'transparent' } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "17px", background: 'transparent', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#cb444a", backgroundColor: 'transparent', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: 'transparent' },
}));


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));



// const breadcrumbs = [
//     <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
//     <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Fee Configs</Typography>
// ]

export default function FeeeConfigsAdmin() {
   
    const [deactivateFeeModal, setDeactivateModal] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [selectedBusinessNo, setSelectedBusinessNo] = useState('')
    const [openDeactivateZPMAccount, setOpenDeactivateZPMAccount] = useState(false)
    const [openDeactivateZPMCardAccount, setOpenDeactivateZPMCardAccount] = useState(false)
    const [accountType, setAccountType] = useState('')
    const userGroup = localStorage.getItem('group')
    const [defaultTabValue ,setDefaultTabValue] = useState('pocketMoneyActivation')
    
    const [tabValue, setTabValue] = useState('All')
    useEffect(()=>{
       if(userGroup === 'Partner'){
             setTabValue('zpmcardcollectionaccount')
        }
    },[userGroup])

    console.log(defaultTabValue ,'defaultTabValue')

    useEffect(() => {
        if (tabValue === 'pocketMoneyActivation') {
            setAccountType('zpmactivationFee')
        } if (tabValue === 'zpmaccount') {
            setAccountType('pocketmoney')
        }
        if(tabValue === "All") {
            setAccountType('')
        }
    }, [tabValue])

    useEffect(()=>{
        if(userGroup === 'Admin'){

        }

    })


    // handle open deactivate modal

    const handleOpenDeactivateZPMAccount = () => {
        setOpenDeactivateZPMAccount(true)
    }

    const handleCloseDeactivateZPMAccount = () => {
        setOpenDeactivateZPMAccount(false)
    }

    const handleOpenDeactivateZPMCardAccount = () => {
        setOpenDeactivateZPMCardAccount(true)
    }
    const handleCloseDeactivateZPMCardAccount = () => {
        setOpenDeactivateZPMCardAccount(false)
    }

    const handleOenDeactivateFeeModal = () => {
        setDeactivateModal(!deactivateFeeModal)
    }

    // handle close deactivate modal

    const handleCloseDeactivateFeeModal = () => {
        setDeactivateModal(false)
    }




    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        console.log("this is the tabvalue im getting", newValue)
        setTabValue(newValue);
    };

    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, businessNumber] = newqueryParamValue.split("?")

    const configMethods = new ConfigurationFunctions()
    const [searchValue, setInputValue] = useState("");

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [accountSearchValue, setAccountSearchValue] = useState('')


    // zpm collection accoutn

    const [dataGridPageSizecollection, setPageSizecollection] = useState(10);
    const [pageStateCollection, setPageStateCollection] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSizecollection });


    function getZPMCardAccount() {
        setPageStateCollection({ ...pageStateCollection, isLoading: true })
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getZedPocketMoneyCardAcounts?page=${pageStateCollection.page}&limit=${dataGridPageSizecollection}&searchValue=${searchValue}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                console.log(data, '>>>>>>>')
                if (data?.status === 200 || data?.status === 201) {
                    setPageStateCollection({ ...pageStateCollection, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }

            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getZPMCardAccount()
    }, [queryParamValue, searchValue, pageStateCollection?.page, dataGridPageSizecollection, tabValue])

    console.log(pageStateCollection.data, 'pageStateCollection')


    function getgetTransactionMode() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getZedPayItBusinesss?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&accountType=${accountType}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getgetTransactionMode()
    }, [queryParamValue, pageState?.page, dataGridPageSize, searchValue, accountType, tabValue])

    function handleHandleDeactivateFee() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/deactiveKcbMpesa`,
                body: {
                    businessNumber: selectedBusinessNo,
                    accountType: "zpmactivationFee"
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({ state: 'true', message: data?.response?.message })
                    handleCloseDeactivateFeeModal();
                    getgetTransactionMode()
                }
            })
        } catch (error) {

        }
    }


    const RenderCell = (params) => {
        console.log(params?.row , 'params')
        const [anchorEl, setAnchorEl] = useState(null);
        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };
        const handleActionClose = () => { setAnchorEl(null); };
        return (
            <Grid item>
                <MoreVertIcon onClick={handleClick} />
                <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                    {/* <MenuItem disableRipple onClick={() => {
                        if (tabValue === 'zpmaccount') {
                            navigate(`/feeconfigs?editzpmAccountConfig?${params?.row?.businessNumber}?${params?.row?.businessName}`);
                        } else if (tabValue === 'zpmcardcollectionaccount') {
                            navigate(`/feeconfigs?editzpmCardAccountConfig?${params?.row?.businessNumber}?${params?.row?.businessName}?${params?.row?.accountNumber}`);
                        }
                        setAnchorEl(null);
                    }}
                    >
                        <Edit style={{ color: '', cursor: 'pointer' }} />
                        Edit
                    </MenuItem> */}
                     <MenuItem sx={{display:'flex', alignItems:'center', gap:1}} disableRipple onClick={() => navigate(`/feeconfigs?editConfig?${params?.row?.businessNumber}?${params?.row?.businessName}`)}>
                         <Edit style={{ color: '', cursor: 'pointer' }} /> Edit
                    </MenuItem>

                    {/* <MenuItem disableRipple onClick={() => { setAnchorEl(null) }}> <DoNotDisturbAltIcon onClick={() => { setSelectedBusinessNo(params?.row?.businessNumber); handleOenDeactivateFeeModal() }} style={{ color: '#dc3545', cursor: 'pointer' }} />Deactivate</MenuItem> */}
                </StyledMenu>
            </Grid>
        )
    }

    const zpmAllBusiness = [
        { field: "businessName", headerName: "BusinessName", flex: 1 },
        { field: "businessNumber", headerName: "Business ID", flex: 1 },
        { field: 'businessEmail', headerName: 'Email', flex: 1 },
        { field: 'businessPhone', headerName: 'Phone No', flex: 1 },
        { field: 'Action' , headerName: 'Action' , flex: 1 , renderCell: RenderCell}
    ]



    const zpmaccountColumns = [
        { field: "businessName", headerName: "BusinessName", flex: 1 },
        { field: "businessNumber", headerName: "Business ID", flex: 1 },
        { field: 'businessEmail', headerName: 'Email', flex: 1 },
        { field: 'businessPhone', headerName: 'Phone No', flex: 1 },
        { field: 'beneficiaryAccount', headerName: 'School/Shop Account', flex: 1 },
        { field: 'creditAccount', headerName: 'Zed Master Account', flex: 1 },
        { field: 'accountOwner', headerName: 'Account Owner', flex: 1 },
        {
            field: "action", headerName: "Action", flex: 1, renderCell: RenderCell
        },
    ]

    const zpmcardcollectionaccountColumns = [
        { field: "businessName", headerName: "BusinessName", flex: 1 },
        { field: "businessNumber", headerName: "Business ID", flex: 1 },
        { field: 'businessEmail', headerName: 'Email', flex: 1 },
        { field: 'businessPhone', headerName: 'Phone No', flex: 1 },
        { field: 'accountNumber', headerName: 'Card Collection Account', flex: 1 },
        { field: "action", headerName: "Action", flex: 1, renderCell: RenderCell }
    ]


    const column = [
        { field: "businessName", headerName: "BusinessName", flex: 1 },
        { field: "businessNumber", headerName: "Business ID", flex: 1 },
        { field: 'businessEmail', headerName: 'Email', flex: 1 },
        { field: 'businessPhone', headerName: 'Phone No', flex: 1 },
        { field: 'activationfee', headerName: 'Activation Fee', flex: 1 },
        { field: 'creditAccount', headerName: 'Credit Account', flex: 1 },
        {
            field: "action", headerName: "Action", flex: 1, renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        {/* <Edit style={{ color: '', cursor: 'pointer' }} onClick={() => navigate(`/feeconfigs?editConfig?${params?.row?.businessNumber}?${params?.row?.businessName}`)} /> */}
                        <DoNotDisturbAltIcon onClick={() => { setSelectedBusinessNo(params?.row?.businessNumber); handleOenDeactivateFeeModal() }} style={{ color: '#dc3545', cursor: 'pointer' }} />
                    </div>
                )
            }
        },
    ];

    const allBusinessColumn = [
        { field: "businessName", headerName: "BusinessName", flex: 1 },
        { field: "businessNumber", headerName: "Business ID", flex: 1 },
        { field: 'businessEmail', headerName: 'Email', flex: 1 },
        { field: 'businessPhone', headerName: 'Phone No', flex: 1 },
        { field: 'ActiveConfigsCount', headerName: 'Active Configs', flex: 1 },
        {
            field: "action", headerName: "Action", flex: 1, renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <Edit style={{ color: '', cursor: 'pointer' }} onClick={() => navigate(`/feeconfigs?editConfig?${params?.row?.businessNumber}?${params?.row?.businessName}`)} />
                        {/* <DoNotDisturbAltIcon onClick={() => { setSelectedBusinessNo(params?.row?.businessNumber); handleOenDeactivateFeeModal() }} style={{ color: '#dc3545', cursor: 'pointer' }} /> */}
                    </div>
                )
            }
        },
    ];


    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        businessEmail: config?.businessEmail,
        businessPhone: DoMaskValue(config?.businessPhone),
        businessName: config?.businessName,
        activationfee: config?.activationFee === 'null' ? 'N/A' : config?.activationFee,
        creditAccount: config?.creditAccount ? config?.creditAccount : 'N/A',
        businessNumber: config?.businessNumber,
        beneficiaryAccount: config?.beneficiaryAccount ? config?.beneficiaryAccount : 'N/A',
        accountOwner: config?.accountOwner ? config?.accountOwner : 'N/A',
        ActiveConfigsCount: config?.ActiveConfigsCount
    }))


    // /api/v1/setUpPocketMoneyAccount


    const configDataZPM = pageStateCollection?.data?.map((config) => ({
        id: config._id,
        businessEmail: config?.businessEmail,
        businessPhone: DoMaskValue(config?.businessPhone),
        businessName: config?.businessName,
        accountNumber: config?.accountNumber ? config?.accountNumber : 'N/A',
        businessNumber: config?.businessNumber,
    }))



{/* <EditPocketMoneyConfig /> : queryParamValue === 'editzpmAccountConfig' ? <ZpmMasterAccountSetUp /> : queryParamValue === 'editzpmCardAccountConfig' ? <ZPMEditCardAccount /> : */}
    return (
        <Grid container direction={'column'} p={2}>
            {queryParamValue === 'editConfig' ? <EditZpmConfigs /> :  <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item mt={2}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "#707070",
                                "&:hover": { cursor: "pointer" },
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        />
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Fee Configs</Typography>
                    </Stack>
                </Grid>
                <Grid item mt={2}>
                    {/* <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs> */}
                    <Breadcrumb name={'Fee Configs'} />
                </Grid>
                <Grid item mt={2} >
                    <TabContext value={tabValue}>
                        {userGroup === 'Admin' ?
                            <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                                <AntTab label="All" value="All" />
                                <AntTab label="Pocket Money Activation Fee" value="pocketMoneyActivation" />
                                <AntTab label="Convenience Fee" value="convenienceFee" style={{ marginLeft: '20px' }} />
                                <AntTab label="Annual Maintenance Fee" value="annualmantainancefee" style={{ marginLeft: '20px' }} />
                                <AntTab label="ZPM Account" value="zpmaccount" style={{ marginLeft: '20px' }} />
                                <AntTab label="ZPM Card Collection Account" value="zpmcardcollectionaccount" style={{ marginLeft: '20px' }} />
                            </AntTabs> : userGroup === 'Partner' ?
                                <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                                    <AntTab label="ZPM Card Collection Account" style={{ color: "#cb444a" }} value="zpmcardcollectionaccount" />
                                </AntTabs>
                                : null}
                        <TabPanel value="All" style={{ marginLeft: '-30px' }}> 

                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Grid item width={"30%"}>
                                        <SearchFilter   placeholder={"Search Business ..."} setInputValue={setInputValue} />    
                                        </Grid>
                                    <CustomDataGrid customStyles={{ border: "none", height: "550px", width: "100%" }} column={allBusinessColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="pocketMoneyActivation" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setInputValue} />
                                        </Grid>

                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "550px", width: "100%" }} column={column} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="convenienceFee" style={{ marginLeft: '-30px' }}>
                            <Grid item>
                                <ConvenienceFeeMgt />
                            </Grid>
                        </TabPanel>
                        <TabPanel value="annualmantainancefee" style={{ marginLeft: '-30px' }}>
                            <Grid item>
                                <AnnualMaintenanceFee />
                            </Grid>
                        </TabPanel>
                        <TabPanel value="zpmaccount" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setInputValue} />
                                        </Grid>

                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={'100%'}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "550px", width: "100%" }} column={zpmaccountColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="zpmcardcollectionaccount" style={{ marginLeft: '-30px' }}>
                            <Grid item marginLeft={2}>
                                <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                    <Grid item width={"30%"}>
                                        <SearchFilter placeholder={"Search"} setInputValue={setInputValue} />
                                    </Grid>

                                    <Grid item>{/* <Exports /> */}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item width={'100%'}>
                                <CustomDataGrid customStyles={{ border: "none", height: "550px", width: "100%" }} column={zpmcardcollectionaccountColumns} setPageSize={setPageSizecollection} setPageState={setPageStateCollection} pageState={pageStateCollection} dataGridPageSize={dataGridPageSizecollection} row={configDataZPM} />
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>
            }

            <Modal
                open={deactivateFeeModal}
                onClose={handleCloseDeactivateFeeModal}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                // slotProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                // BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={WarningImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Deactivate Activation Fee ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to deactivate activation fee
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeactivateFeeModal} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleHandleDeactivateFee} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDeactivateZPMAccount}
                onClose={handleCloseDeactivateZPMAccount}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                // slotProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                // BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={WarningImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Deactivate Activation Fee ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Selected account will be deleted . This action cannot be undone
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeactivateFeeModal} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleHandleDeactivateFee} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDeactivateZPMCardAccount}
                onClose={handleCloseDeactivateZPMCardAccount}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                // slotProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                // BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={WarningImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Deactivate Activation Fee ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to deactivate activation fee
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeactivateFeeModal} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleHandleDeactivateFee} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}